.filter_nav {
    margin-left: 0 !important;
    max-width: inherit !important;
    padding-left: 15px !important;
}

.filter_button {
}

.filter_icon {
    margin-right: 5px !important;
}

.date_picker_block {
    margin-left: 20px !important;
}

.theme_buttons {
    justify-content: flex-end !important;
    margin-bottom: 20px;
    margin-top: -15px;
}

.time_filter_col {
    margin-left: 15px !important;
}

.event_label {
    margin-right: 10px;
}

.event_details_value {
    padding-left: 0 !important;
}

.progress_bar {
    margin: 15px -15px -15px -30px !important;
}

.wm-label {
    background-color: aqua;
    color: black;
    font-weight: bold;
    font-family: serif;
}
