.details_modal_width {
    min-width: 97%;
}

.table-wrapper {
    overflow: auto;
    display: inline-block;
}

.details_table_pagination {
    display: flex !important;
    justify-content: flex-end !important;
    flex-wrap: wrap !important;
}
