html {
    overflow: -moz-scrollbars-vertical;
    overflow-y: scroll;
    overflow-x: auto;
}

.home_class {
    max-width: 100% !important;
    padding-bottom: 20px !important;
}

.padding-10 {
    padding: 10px !important;
}

.center {
    margin: auto;
}

.flex-content {
    display: flex;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.nav-tabs .nav-link {
    border-top-right-radius: 20px !important;
}
