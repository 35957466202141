.action_buttons_col {
    text-align: right !important;
}

.card_title {
    text-align: left !important;
}

#downloadButton {
    margin-right: 5px;
}

#detailsButton {
    padding-right: 12px;
    padding-left: 12px;
}

.search_key_badge_button {
    background-color: #0d6efd !important;
    border-color: #0d6efd !important;
    border-top-left-radius: 30px !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 30px !important;
    margin-bottom: 10px !important;
}

.query_key_remove_button {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 30px !important;
    border-bottom-right-radius: 30px !important;
    border-bottom-left-radius: 0 !important;
    margin-bottom: 10px !important;
}

.status_code_badge {
    border-radius: 30px;
    padding: 3px 10px;
    margin: 5px;
}

.status_code_row {
    flex-direction: row-reverse !important;
}

.drop_down_btn {
    cursor: pointer;
}

.drop_down {
    position: relative;
    display: inline-block;
}

.drop_down_content {
    display: none;
    position: absolute;
    z-index: 1;
}

.drop_down_item {
    width: 190px !important;
    padding: 10px;
    border-radius: 0px !important;
}

.drop_down_content_col {
    display: flex;
}

.drop_down:hover .drop_down_content {
    display: block;
}
