:root {
    --background-dark: #222423;
    --background-dark-2: #1e1e1e;
    --background-dark-window: #212121;
    --text-light: #000000;
    --text-dark: #e1e1e1;
}

.light-theme {
    background-color: var(--background-light);
}

.dark-theme {
    background-color: var(--background-dark-window) !important;
    color: var(--text-dark) !important;
    --bs-body-color: var(--text-dark) !important;
}

.dark-theme .filter_nav {
    --bs-card-bg: var(--background-dark) !important;
    --bs-card-color: var(--text-dark) !important;
}
.dark-theme .card {
    --bs-card-bg: var(--background-dark-2);
    --bs-card-color: var(--text-dark) !important;
}

.dark-theme .table {
    --bs-table-bg: var(--background-dark-2) !important;
    --bs-table-color: var(--text-dark) !important;
    color: var(--text-dark) !important;
}

.dark-theme .table > :not(caption) > * > * {
    --bs-table-bg: var(--background-dark-2) !important;
    --bs-table-color: var(--text-dark) !important;
    color: var(--text-dark);
}

.dark-theme .btn-close {
    --bs-btn-close-color: var(--text-dark) !important;
}

.dark-theme .modal {
    --bs-modal-bg: var(--background-dark) !important;
}

.dark-theme .form-control,
.dark-theme .form-select,
.dark-theme .form-control:focus,
.dark-theme .form-select:focus {
    color: var(--text-dark) !important;
    background-color: var(--background-dark);
    border: 1px solid #0d6efd;
}

.dark-theme .tab_manager_theme {
    background-color: var(--background-dark) !important;
}

.dark-theme .nav-tabs {
    --bs-nav-tabs-border-color: #0d6efd !important;
    --bs-nav-tabs-border-width: 0.5px !important;
    background-color: var(--background-dark) !important;
}

.dark-theme .nav-tabs .nav_item_div {
    --bs-nav-tabs-border-color: #0d6efd !important;
    --bs-nav-tabs-border-width: 0.5px !important;
    background-color: var(--background-dark) !important;
}

.dark-theme .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    border-color: #0d6efd;
}

.dark-theme .nav-tabs .nav_item_div .nav-link.not_active {
    background-color: var(--background-dark-2) !important;
}

.dark-theme .nav-tabs .nav-link {
    border-width: 0px 0px 0px 0px !important;
    border-color: #0d6efd;
    -webkit-box-shadow: 3px 0px 3px 0px rgba(13, 110, 253, 0.6);
}

.dark-theme ::placeholder {
    color: #8e8e8e !important;
}

.dark-theme ::-ms-input-placeholder {
    color: #8e8e8e !important;
}

.dark-theme .drop_dow_content {
    background-color: var(--background-dark-2) !important;
}

.dark-theme .details_table_pagination {
    --bs-pagination-bg: var(--background-dark) !important;
    --bs-pagination-border-width: 0px !important;
    --bs-pagination-color: var(--text-dark) !important;
}
